import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "../router/index";
Vue.use(VueAxios, axios);
Vue.use(Vuex);

import { EncryptStorage } from "encrypt-storage";
export const encryptStorage = new EncryptStorage("secret-key-value", {
  prefix: "@talabk",
  encAlgorithm: "Rabbit",
});
export default new Vuex.Store({
  state: {
    connection_snack: false,
    drawer: true,
    mini: false,
    def_path: "https://appship.chekly.com",
    //def_path: "http://127.0.0.1:8000",
    token: encryptStorage.getItem("token_shipments") || null,
    shipments: encryptStorage.getItem("shipments"),
    break_point: 0,
    count_active_orders: 0,
    currencies: {
      done: false,
      data: [],
    },
    countries: {
      done: false,
      data: [],
    },
  },
  getters: {
    loggedin(state) {
      return state.token != null;
    },
    items: (stat) => (items) => {
      return stat[items];
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
    },
    logout(state) {
      state.token = null;
      encryptStorage.removeItem("token_shipments");
      encryptStorage.removeItem("shipments");
    },
    user(state, user) {
      state.shipments = user;
    },

    store_items(state, items) {
      state[items.items].data = items.data;
      if (items.data.length) {
        state[items.items].done = true;
      }
    },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("phone", cred.phone);
        var config = {
          method: "post",
          url: "login",
          headers: {
            Accept: "application/json",
          },
          data: formData,
        };
        axios(config)
          .then((res) => {
            const token = res.data.data.token;
            encryptStorage.setItem("token_shipments", token);
            encryptStorage.setItem("shipments", res.data.data);
            context.commit("login", token);
            context.commit("user", res.data.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // public request this function handel any requset type like post & get Etc ...
    public__request(context, cred) {
      return new Promise((resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            Accept: "application/json",
            "Content-Type": cred.config.type
              ? cred.config.type
              : "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || [],
        };
        axios(config)
          .then((res) => {
            resolve(res);
            context.state.connection_snack = false;
            if (cred.get) {
              context.commit("public__request", {
                data: res.data,
                name: cred.get,
              });
            }
          })
          .catch((err) => {
            if (!err.response) {
              context.state.connection_snack = true;
            } else {
              reject(err);
            }
            if (err.response.status == 401) {
              context.commit("logout");
              router.push("/");
            }
          });
      });
    },
  },
  modules: {},
});
