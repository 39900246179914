import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import AppAside from "@/layout/AppAside.vue";
Vue.use(VueRouter);
Vue.component("AppAside", AppAside);
import notfound from "@/views/not_found.vue";
const login = () => import("@/views/Login.vue");

const shipments = () => import("@/views/shipments");

const problem = () => import("@/views/problem");
const routes = [

  {
    path: "/shipments",
    name: "shipments",
    component: shipments,
    meta: {
      title: "delivery",
      requiresAuth: true,
      super_admin: true,
    },
  },


  //--------------- public
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
      requiresAuth: false,
    },
  },

  {
    path: "/problem",
    name: "problem",
    component: problem,
    meta: {
      title: "problem",
      requiresAuth: false,
    },
  },
  {
    path: "*",
    name: "notfound",
    component: notfound,
    meta: {
      title: "notfound",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;



router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;

  if (to.name != "notfound") {
    if (!to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.loggedin) {
        next();
      } else {
        next('shipments');
      }
    } else if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.loggedin) {
        next({
          name: "login",
        });
      } else {
        next();
      }
    }
  }
});


